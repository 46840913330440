import React from 'react'

import { useTranslation } from '../../../utils/providers'

export default function SkipLink({ forwardedRef, target }) {
  const { t } = useTranslation()
  return (
    <a className="skip-link" href={target} ref={forwardedRef}>
      {t('SKIP_LINK_MAIN_CONTENT')}
    </a>
  )
}
