import React, { Component } from 'react'
import classNames from 'classnames'
import {
  dispatchOverlayClickedEvent,
  dispatchPageNoScroll,
  dispatchPageScroll,
} from '../../../utils'

export default class Overlay extends Component {
  state = {
    isVisible: false,
  }

  componentDidMount() {
    window.addEventListener('overlay:show', this.showOverlay)
    window.addEventListener('overlay:hide', this.hideOverlay)
    window.addEventListener('overlay:clicked', this.hideOverlay)
  }

  componentWillUnmount() {
    window.removeEventListener('overlay:show', this.showOverlay)
    window.removeEventListener('overlay:hide', this.hideOverlay)
    window.removeEventListener('overlay:clicked', this.hideOverlay)
  }

  showOverlay = (evt) => {
    const showScrollbar = evt?.detail?.showScrollbar || false
    this.setState({ isVisible: true })
    if (!showScrollbar) {
      dispatchPageNoScroll()
    }
  }

  hideOverlay = () => {
    this.setState({ isVisible: false })
    dispatchPageScroll()
  }

  handleOverlayClick = () => {
    this.hideOverlay()
    dispatchOverlayClickedEvent()
  }

  render() {
    const { children } = this.props
    const { isVisible } = this.state

    const classes = classNames('overlay', {
      ['overlay-visible']: isVisible,
    })

    return (
      <div className={classes} onClick={this.handleOverlayClick}>
        {children}
      </div>
    )
  }
}
