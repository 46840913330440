import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useConfiguration } from '../../../utils/index.js'

const BackgroundImage = ({ image }) => {
  const { getImageLink } = useConfiguration()

  useEffect(() => {
    if (image) {
      const srcFull = getImageLink({ source: image })
      document.body.style.backgroundImage = `url(${srcFull})`
      document.body.classList.add('background-image')
    }
  }, [])

  return <></>
}

BackgroundImage.propTypes = {
  image: PropTypes.string,
}

BackgroundImage.defaultProps = {
  image: '',
}

export default BackgroundImage
