import React from 'react'
import Head from 'next/head'

import {
  BackendApiProvider,
  CartProvider,
  ConfigurationProvider,
  CustomCouponingProvider,
  CustomerProvider,
  FeatureFlagProvider,
  GlobalDataProvider,
  ResizeProvider,
  SnippetProvider,
  TranslationProvider,
  getDefaultLanguage,
} from '../utils/providers'
import BaseLayout from '../patterns/Alphapet/BaseLayout'

import * as fonts from '../config/fonts.json'

const preloadFonts = Object.values(fonts).filter((v) => v.preload) || []

export default function getSharedLayout(Component, pageProps) {
  const { pageData, snippets, device } = pageProps || {}
  const { language = getDefaultLanguage() } = pageData || {}

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="preconnect"
          href="https://app.usercentrics.eu"
          crossOrigin="anonymous"
        />
        <link rel="dns-prefetch" href="https://app.usercentrics.eu" />
        {process.env.SHOP_BACKEND === 'shopify' && (
          <>
            <link rel="preconnect" href={process.env.BACKEND_API_URL} />
            <link rel="dns-prefetch" href={process.env.BACKEND_API_URL} />
          </>
        )}
        {preloadFonts?.map(({ fileName, fileTypes }) => (
          <link
            key={fileName}
            rel="preload"
            href={`/assets/fonts/${fileName}.${fileTypes[0]}`}
            as="font"
            type={`font/${fileTypes[0]}`}
            crossOrigin="anonymous"
          />
        ))}
      </Head>

      <GlobalDataProvider {...pageProps}>
        <ConfigurationProvider assetUrl={process.env.MAKAIRA_ASSET_URL}>
          <BackendApiProvider>
            <TranslationProvider language={language}>
              <SnippetProvider snippets={snippets}>
                <FeatureFlagProvider>
                  <CustomCouponingProvider>
                    <CustomerProvider>
                      <CartProvider>
                        <ResizeProvider initialDevice={device}>
                          <BaseLayout>{Component}</BaseLayout>
                        </ResizeProvider>
                      </CartProvider>
                    </CustomerProvider>
                  </CustomCouponingProvider>
                </FeatureFlagProvider>
              </SnippetProvider>
            </TranslationProvider>
          </BackendApiProvider>
        </ConfigurationProvider>
      </GlobalDataProvider>
    </>
  )
}
