import React, { useState, useEffect } from 'react'

import { Header } from '../../patterns'
import {
  useGlobalData,
  useTranslation,
  submitSearchForm,
  fetchAutosuggestResult,
  fetchMenuData,
  useSnippetContent,
} from '../../utils'

export default function HeaderWithProps() {
  const { menuData, params } = useGlobalData()
  const { language } = useTranslation()
  const [menu, setMenu] = useState([])

  useEffect(() => {
    let isMounted = true

    if (!menuData) {
      fetchMenuData().then((menuResponse) => {
        if (menuResponse && isMounted) {
          setMenu(menuResponse)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [menuData])

  const config = useSnippetContent('header_config') || {}

  const headerProps = {
    menu: menuData ? menuData : menu,
    fetchAutosuggestResult: (searchPhrase) =>
      fetchAutosuggestResult({ searchPhrase, language }),
    submitSearchForm: (searchPhrase) =>
      submitSearchForm({ searchPhrase, language }),
  }

  return (
    <>
      <Header
        showInstanceBanner={true}
        searchPhraseParam={params?.searchPhrase}
        config={config}
        menuData={menuData}
        {...headerProps}
      />
    </>
  )
}
