import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useMediaResize } from '../..'

function useBubbleWatcher() {
  const { windowWidth } = useMediaResize()
  const router = useRouter()

  const positionBubbles = () => {
    window.dispatchEvent(new Event('bubble:position:update'))
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', positionBubbles)
    return () => {
      router.events.off('routeChangeComplete', positionBubbles)
    }
  }, [])

  useEffect(() => {
    positionBubbles()
  }, [windowWidth])

  return null
}

export default useBubbleWatcher
